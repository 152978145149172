var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_vm._m(0),_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"},[_c('div',{staticClass:"py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"},[_c('div',{staticClass:"shadow p-5 overflow-hidden border-b border-gray-200 sm:rounded-lg"},[_c('div',{attrs:{"id":"element-to-print"}},[(_vm.$route.params.reportOn === 'branch_sales_report')?[_c('NewBranchReport',{attrs:{"resultData":_vm.$route.params.result,"fromDate":_vm.$route.params.from,"toDate":_vm.$route.params.to}})]:_vm._e(),(_vm.$route.params.reportOn === 'branch_sales')?[(_vm.$route.params.branch === 'all')?_c('all-branch-sales',{attrs:{"data":_vm.$route.params.result}}):_vm._e(),(_vm.$route.params.branch >= 1 &&
                                _vm.$route.params.staff == 'all'
                                )?_c('branch-sales',{attrs:{"showHeader":false,"data":_vm.$route.params.result}}):_vm._e(),(_vm.$route.params.branch >= 1 &&
                                _vm.$route.params.staff >= 1
                                )?_c('branch-staff-sales',{attrs:{"data":_vm.$route.params.result}}):_vm._e()]:_vm._e(),(_vm.$route.params.reportOn === 'deposits')?[(_vm.$route.params.branch === 'all')?_c('all-deposits-report',{attrs:{"data":_vm.$route.params.result}}):_vm._e(),(_vm.$route.params.branch >= 1 &&
                                _vm.$route.params.staff == 'all'
                                )?_c('deposits-report',{attrs:{"showHeader":false,"data":_vm.$route.params.result}}):_vm._e(),(_vm.$route.params.branch >= 1 &&
                                _vm.$route.params.staff >= 1
                                )?_c('deposit-staff-report',{attrs:{"data":_vm.$route.params.result}}):_vm._e()]:_vm._e(),(_vm.$route.params.reportOn === 'worksheets_report')?[_c('WorksheetsReportVue',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn === 'daily_cash_collection_report')?[_c('CashCollectionReport',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn === 'float_replenishments')?[_c('FloatRepleshmentReport',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn === 'account_replenishments')?[_c('AccountRepleshmentReport',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn === 'transactions')?[_c('transactions',{attrs:{"data":_vm.$route.params.result.data}})]:_vm._e(),(_vm.$route.params.reportOn === 'daily_sales')?[_c('daily-sales',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn === 'tx_transactions'
                            )?[_c('TXTransactionReport',{attrs:{"data":_vm.$route.params.result.data}})]:_vm._e(),(_vm.$route.params.reportOn === 'agents_sales')?[_c('AgentTransactionsReports',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn ===
                            'commission_center'
                            )?[(_vm.$route.params.reportBy ===
                                'chapman_branch'
                                )?_c('BranchCommission',{attrs:{"data":_vm.$route.params.result}}):_vm._e(),(_vm.$route.params.reportBy === 'tx_agents'
                                )?_c('TxAgentsCommission',{attrs:{"data":_vm.$route.params.result}}):_vm._e()]:_vm._e(),(_vm.$route.params.reportOn === 'revise_report'
                            )?[_c('ReviceReport',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn === 'balance_report'
                            )?[_c('BalanceReport',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn ===
                            'superagents_sales'
                            )?[_c('SuperagentReport',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn === 'sms_report')?[_c('SmsReport',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn === 'customer')?[_c('button',{staticClass:"bg-green-400 px-6 py-2 rounded-md hover:bg-green-500 disabled:opacity-50",on:{"click":function($event){return _vm.downloadDocument()}}},[(!_vm.isDownloading)?_c('span',[_vm._v("Download XLSX")]):_c('span',[_vm._v("Downloading...")])])]:_vm._e(),(_vm.$route.params.reportOn ===
                            'nawec_running_balance'
                            )?[_c('NawecRunningBalance',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn ===
                            'denominator_report'
                            )?[_c('DenominatorReports',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn ===
                            'daily_cash_reconciliation_report'
                            )?[_c('CashReconciliationReport',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn ===
                            'financial_summary_report'
                            )?[_c('FinancialSummaryReport',{attrs:{"data":_vm.$route.params.result}})]:_vm._e(),(_vm.$route.params.reportOn ===
                            'nawec_sales_summary_report'
                            )?[_c('NawecSummaryReport',{attrs:{"data":_vm.$route.params.result}})]:_vm._e()],2)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-between items-center mb-6"},[_c('div')])}]

export { render, staticRenderFns }