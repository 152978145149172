<template>
  <div>
    <div class="text-right">
   
    </div>

    <div class="flex text-lg mb-4">
      <h1 class="pr-4 border-r uppercase font-bold">
        {{ $route.params.reportOn.replace(/_/g, " ") }}
      </h1>
      <h1 class="px-4 border-r">
        From:
        <span class="font-bold">{{
          $route.params.from | moment("ddd, MMM Do YYYY")
        }}</span>
      </h1>
      <h1 class="px-4">
        To:
        <span class="font-bold">{{
          $route.params.to | moment("ddd, MMM Do YYYY")
        }}</span>
      </h1>
    </div>
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="
              px-6
              py-3
              text-left text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Nawec Balance
          </th>
          <th
            scope="col"
            class="
              px-6
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Date / Time
          </th>
         
        </tr>
      </thead>

      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="(transaction, i) in data" :key="i">
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="flex items-center">
              <div class="ml-4">
                <div class="text-sm font-medium text-gray-900">
                  GMD {{ transaction.balance  }}
                </div>
              </div>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              {{ transaction.created_at }}
            </div>
          </td>
         
        </tr>

     

        <!-- More people... -->
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  props: ["data"],

  data: () => ({
    downloadLoading: false,

    filename: "",
    autoWidth: true,
    bookType: "xlsx",
  }),

  computed: {
   
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getFileName() {
      return (
        this.$route.params.reportOn +
        "-" +
        this.$route.params.from +
        "-" +
        this.$route.params.to
      );
    },

    handleDownload() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "Branch",
          "Transactions",
          "Amount",
          "Commission",
          "Total",
        ];
        const filterVal = [
          "branch",
          "transactions",
          "amount",
          "commission",
          "total",
        ];
        const list = this.data;
        console.log("LIST", list);
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.getFileName(),
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
        this.downloadLoading = false;
      });
    },

    formatJson(filterVal, jsonData) {
      return Object.keys(jsonData).map((v) => {
        console.log();
        return filterVal.map((j) => {
          console.log("J", j);
          console.log();

          if (j === "branch") {
            return v;
          } else if (j === "total") {
            return (
              Number(jsonData[v]["amount"]) + Number(jsonData[v]["commission"])
            );
          } else {
            return jsonData[v][j];
          }
        });
      });
    },
  },
};
</script>