<template>
    <div class="">
        <div class="flex justify-between items-center mb-6">
            <div></div>
        </div>

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow p-5 overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <div id="element-to-print">
                            <template v-if="$route.params.reportOn === 'branch_sales_report'">
                                <NewBranchReport :resultData="$route.params.result" :fromDate="$route.params.from"
                                    :toDate="$route.params.to" />
                            </template>

                            <template v-if="$route.params.reportOn === 'branch_sales'">
                                <all-branch-sales v-if="$route.params.branch === 'all'" :data="$route.params.result" />
                                <branch-sales :showHeader="false" v-if="$route.params.branch >= 1 &&
                                    $route.params.staff == 'all'
                                    " :data="$route.params.result" />

                                <branch-staff-sales v-if="$route.params.branch >= 1 &&
                                    $route.params.staff >= 1
                                    " :data="$route.params.result" />
                            </template>

                            <template v-if="$route.params.reportOn === 'deposits'">
                                <all-deposits-report v-if="$route.params.branch === 'all'" :data="$route.params.result" />
                                <deposits-report :showHeader="false" v-if="$route.params.branch >= 1 &&
                                    $route.params.staff == 'all'
                                    " :data="$route.params.result" />

                                <deposit-staff-report v-if="$route.params.branch >= 1 &&
                                    $route.params.staff >= 1
                                    " :data="$route.params.result" />
                            </template>
                            <template v-if="$route.params.reportOn === 'worksheets_report'">

                                <WorksheetsReportVue :data="$route.params.result" />
                            </template>
                            <template v-if="$route.params.reportOn === 'daily_cash_collection_report'">

                                <CashCollectionReport :data="$route.params.result" />
                            </template>

                            <template v-if="$route.params.reportOn === 'float_replenishments'">
                                <FloatRepleshmentReport :data="$route.params.result" />
                            </template>

                            <template v-if="$route.params.reportOn === 'account_replenishments'">
                                <AccountRepleshmentReport :data="$route.params.result" />
                            </template>


                            <template v-if="$route.params.reportOn === 'transactions'">
                                <transactions :data="$route.params.result.data" />
                            </template>

                            <template v-if="$route.params.reportOn === 'daily_sales'">
                                <daily-sales :data="$route.params.result" />
                            </template>

                            <template v-if="$route.params.reportOn === 'tx_transactions'
                                ">
                                <TXTransactionReport :data="$route.params.result.data" />
                            </template>

                            <template v-if="$route.params.reportOn === 'agents_sales'">
                                <AgentTransactionsReports :data="$route.params.result" />
                            </template>

                            <template v-if="$route.params.reportOn ===
                                'commission_center'
                                ">
                                <BranchCommission v-if="$route.params.reportBy ===
                                    'chapman_branch'
                                    " :data="$route.params.result" />
                                <TxAgentsCommission v-if="$route.params.reportBy === 'tx_agents'
                                    " :data="$route.params.result" />
                            </template>

                            <template v-if="$route.params.reportOn === 'revise_report'
                                ">
                                <ReviceReport :data="$route.params.result" />
                            </template>

                            <template v-if="$route.params.reportOn === 'balance_report'
                                ">
                                <BalanceReport :data="$route.params.result" />
                            </template>

                            <template v-if="$route.params.reportOn ===
                                'superagents_sales'
                                ">
                                <SuperagentReport :data="$route.params.result" />
                            </template>

                            <template v-if="$route.params.reportOn === 'sms_report'">
                                <SmsReport :data="$route.params.result" />
                            </template>

                            <template v-if="$route.params.reportOn === 'customer'">
                                <button @click="downloadDocument()"
                                    class="bg-green-400 px-6 py-2 rounded-md hover:bg-green-500 disabled:opacity-50">
                                    <span v-if="!isDownloading">Download XLSX</span>
                                    <span v-else>Downloading...</span>
                                </button>
                            </template>

                            <template v-if="$route.params.reportOn ===
                                'nawec_running_balance'
                                ">
                                <NawecRunningBalance :data="$route.params.result" />
                            </template>

                            <template v-if="$route.params.reportOn ===
                                'denominator_report'
                                ">
                                <DenominatorReports :data="$route.params.result" />
                            </template>

                            <template v-if="$route.params.reportOn ===
                                'daily_cash_reconciliation_report'
                                ">
                                <CashReconciliationReport :data="$route.params.result" />
                            </template>
                            <template v-if="$route.params.reportOn ===
                                'financial_summary_report'
                                ">
                                <FinancialSummaryReport :data="$route.params.result" />
                            </template>
                            <template v-if="$route.params.reportOn ===
                                'nawec_sales_summary_report'
                                ">
                                <NawecSummaryReport :data="$route.params.result" />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import AllBranchSales from './Docs/AllBranchSales';
import FinancialSummaryReport from './Docs/FinancialSummaryReport';
import NawecSummaryReport from './Docs/NawecSummaryReport';
import DepositsReport from './Docs/DepositsReport';
import AllDepositsReport from './Docs/AllDepositsReport';
import DepositStaffReport from './Docs/DepositStaffReport';
import BranchSales from './Docs/BranchSales';
import BranchStaffSales from './Docs/BranchStaffSales.vue';
import BalanceReport from './Docs/BalanceReport.vue';
import DailySales from './Docs/DailySales.vue';
import ReviceReport from './Docs/ReviceReport.vue';
import Transactions from './Docs/Transactions.vue';
import SuperagentReport from './Docs/SuperagentReport.vue';
import TXTransactionReport from './Docs/TXTransactionReport.vue';
import AgentTransactionsReports from './Docs/AgentTransactionsReports.vue';
import SmsReport from './Docs/SmsReport.vue';

import BranchCommission from './Docs/BranchCommission.vue';
import TxAgentsCommission from './Docs/TxAgentsCommission.vue';
import NawecRunningBalance from './Docs/NawecRunningBalance.vue';
import CashReconciliationReport from './Docs/CashReconciliationReport.vue';
import DenominatorReports from './Docs/DenominatorReports.vue';
import NewBranchReport from './Docs/NewBranchReport.vue';
// import BranchSales from "../../components/Charts/BranchSales";
import WorksheetsReportVue from './Docs/WorksheetsReport.vue'
import CashCollectionReport from './Docs/CashCollectionReport.vue';
import FloatRepleshmentReport from './Docs/FloatRepleshmentReport.vue';
import AccountRepleshmentReport from './Docs/AccountRepleshmentReport.vue';
export default {
    components: {
        SmsReport,
        AllBranchSales,
        BranchSales,
        ReviceReport,
        AllDepositsReport,
        DepositStaffReport,
        BalanceReport,
        DepositsReport,
        BranchCommission,
        BranchStaffSales,
        DailySales,
        Transactions,
        CashReconciliationReport,
        SuperagentReport,
        TXTransactionReport,
        AgentTransactionsReports,
        TxAgentsCommission,
        NawecRunningBalance,
        DenominatorReports,
        FinancialSummaryReport,
        NawecSummaryReport,
        WorksheetsReportVue,
        CashCollectionReport,
        NewBranchReport,
        FloatRepleshmentReport,
        AccountRepleshmentReport
    },

    middleware: 'auth',
    layout: 'default',

    data: () => ({
        branches: null,

        reportOn: '',
        from: '',
        to: '',

        isDownloading: false,
    }),

    created() { },

    methods: {
        getFileName() {
            return (
                this.$route.params.reportOn +
                '-' +
                this.$route.params.from +
                '-' +
                this.$route.params.to
            );
        },

        async downloadDocument() {
            axios({
                url: 'export/customers',
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                let blob = new Blob([response.data]);
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'customers.xlsx';
                link.click();
            });
        },

        exportToPDF() {
            this.isLoading = true;
            var element = document.getElementById('element-to-print');
            var opt = {
                margin: 0,
                filename: `${this.getFileName()}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 1 },
                jsPDF: {
                    unit: 'in',
                    format: 'letter',
                    orientation: 'portrait',
                },
            };

            // New Promise-based usage:
            html2pdf().from(element).set(opt).save();

            // Old monolithic-style usage:
            html2pdf(element, opt);

            this.isLoading = false;
        },

        async generate() {
            this.isLoading = true;

            try {
                const { data } = await axios.get(
                    `/report/generate?from=${this.from}&to=${this.to}&report=${this.reportOn}`
                );

                this.report = data;
                this.isLoading = false;

                return true;
            } catch (e) {
                console.log('Error');
                this.isLoading = false;

                return false;
            }
        },
    },
};
</script>
