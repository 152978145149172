<template>
  <div>
    <div class="text-right">
      <button
        @click="handleDownload()"
        :disabled="downloadLoading"
        class="px-4 py-2 mb-4 bg-gray-50 border rounded-md text-black flex"
      >
        <img src="../../../assets/excel.png" alt="" />
        <span class="mx-3">Download</span>
      </button>
    </div>
    <div class="flex text-lg mb-4">
      <h1 class="pr-4 border-r uppercase font-bold">
        {{ $route.params.reportOn.replace(/_/g, " ") }}
      </h1>
      <h1 class="px-4 border-r">
        From:
        <span class="font-bold">
          {{ $route.params.from | moment("ddd, MMM Do YYYY") }}
        </span>
      </h1>
      <h1 class="px-4">
        To:
        <span class="font-bold">
          {{ $route.params.to | moment("ddd, MMM Do YYYY") }}
        </span>
      </h1>
    </div>

    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Allocations / Date
          </th>

          <!-- <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Branch
          </th> -->

          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Created By
          </th>

          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Bank
          </th>

          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
           Slip NO#
          </th>

          <th
            scope="col"
            class="px-6 py-3 text-xs font-medium text-right text-gray-500 uppercase tracking-wider"
          >
          Amount

          </th>
        </tr>
      </thead>
      <tbody class="" v-for="(replenishment, i) in data.reports" :key="i" >
        <tr >
          <td class="px-6 py-4 whitespace-nowrap cursor-pointer" @click="handleOpenAllocation(replenishment)">
            <div class="flex items-center">
              <template v-if="selectedReplenishment && selectedReplenishment.float_id === replenishment.float_id">
                <i class='bx bx-chevron-down'></i>
              </template>
              <template v-else>
                <i class='bx bx-chevron-up'></i>
              </template>
              <div class="ml-4">
                <div class="text-sm font-medium text-gray-900">
                  {{ replenishment.date }}
                </div>
              </div>
            </div>
          </td>
          <!-- <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              {{ replenishment.branch_name }}
            </div>
          </td> -->
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              {{ replenishment.created_by_name }}
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              {{ replenishment.bank_name }}
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              {{ replenishment.slip_number }}
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-right">
            <div class="text-sm text-gray-900">
              GMD <b>{{ formatPrice(replenishment.amount) }}</b>
            </div>
          </td>
        </tr>
        <tr v-if="selectedReplenishment && selectedReplenishment.float_id === replenishment.float_id">
          <td colspan="6" class="px-6 py-4 whitespace-nowrap">
            <div>
              <p class="text-center font-bold">Allocations</p>
              <div v-for="(al , i) in selectedReplenishment.allocations" :key="i">
                <div class="my-2">
                  <div class="flex justify-between">
                    <p class="font-bold text-blue-700">Account:</p>
                    <p class="font-bold">{{ al.account.name }}</p>
                  </div>
                  <div class="flex justify-between">
                    <p class="font-bold text-blue-700">Balance Before:</p>
                    <p class="font-bold"> D{{ formatPrice(al.balance_before) }}</p>
                  </div>
                  <div class="flex justify-between">
                    <p class="font-bold text-blue-700">Balance allocated:</p>
                    <p class="font-bold">D {{ formatPrice(al.balance_allocated) }}</p>
                  </div>
                  <hr v-if="i < selectedReplenishment.allocations.length - 1" class="my-2 border-t border-gray-400">
                </div>
              </div>
            </div>
          </td>
          
          
        </tr>
      
        
      </tbody>
      <hr v-if="selectedReplenishment">
      <tr>
       
        <td class="px-6 py-4 whitespace-nowrap text-left ">
          <div class="text-sm text-gray-900 font-bold">
         Total Replenishment
          </div>
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-center">
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-center">
    
        </td>
        <!-- <td class="px-6 py-4 whitespace-nowrap text-center">
    
        </td> -->
        <td class="px-6 py-4 whitespace-nowrap text-center">
    
        </td>
        <td class="px-6 py-4 whitespace-nowrap text-right">
          <div class="text-sm text-gray-900">
            GMD <b>{{ formatPrice(data.total_amount) }}</b>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ["data"],
  

  data() {
  return {
    downloadLoading: false,
    filename: "",
    autoWidth: true,
    bookType: "xlsx",
    selectedReplenishment: null,
    
  };
},
methods: {
  handleOpenAllocation(replenishment) {
    console.log('replenishment', replenishment);
    console.log('selectedReplenishment', this.selectedReplenishment);
    if (this.selectedReplenishment && this.selectedReplenishment.float_id === replenishment.float_id) {
      this.selectedReplenishment = null;
    } else {
      this.selectedReplenishment = replenishment;
    }
  },


    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    handleDownload() {
  this.downloadLoading = true;
  import("@/vendor/Export2Excel").then((excel) => {
    const tHeader = [
      "Date",
      "Created By",
      "Bank",
      "Slip NO#",
      "Amount",
      "Total Amount"
    ];

    const filterVal = [
      "date",
      "created_by_name",
      "bank_name",
      "slip_number",
      "amount",
    ];

    const data = this.data.reports.map((replenishment) => {
      const rowData = filterVal.map((key) => {
        if (key === "amount") {
          return `GMD ${this.formatPrice(replenishment[key])}`;
        } else {
          return replenishment[key];
        }
      });
      return rowData;
    });

    // Add the total_amount row
    const totalAmountRow = Array(tHeader.length).fill(""); 
    totalAmountRow[tHeader.length - 1] = `GMD ${this.formatPrice(this.data.total_amount)}`; 
    data.push(totalAmountRow); 

    excel.export_json_to_excel({
      header: tHeader,
      data,
      filename: this.getFileName(),
      autoWidth: this.autoWidth,
      bookType: this.bookType,
    });
    this.downloadLoading = false;
  });
},


    getFileName() {
      return (
        this.$route.params.reportOn +
        "-" +
        this.$route.params.from +
        "-" +
        this.$route.params.to
      );
    },
  },
};
</script>
