<template>
    <div>
      <div class="text-right">
        <button
          @click="handleDownload()"
          class="px-4 py-2 mb-4 bg-gray-50 border rounded-md text-black flex"
        >
          <img src="../../../assets/excel.png" alt="" />
          <span class="mx-3">Download</span>
        </button>
      </div>
  
      <div class="flex text-lg mb-4">
        <h1 class="pr-4 border-r uppercase font-bold">
          {{ $route.params.reportOn.replace(/_/g, " ") }}
        </h1>
        <h1 class="px-4 border-r">
          From:
          <span class="font-bold">{{
            $route.params.from | moment("ddd, MMM Do YYYY")
          }}</span>
        </h1>
        <h1 class="px-4">
          To:
          <span class="font-bold">{{
            $route.params.to | moment("ddd, MMM Do YYYY")
          }}</span>
        </h1>
      </div>
  
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="
                px-6
                py-3
                text-left text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              "
            >
              Date
            </th>
  
            <th
              scope="col"
              class="
                px-6
                py-3
                text-center text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              "
            >
              Branch
            </th>
  
            <th
              scope="col"
              class="
                px-6
                py-3
                text-center text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              "
            >
            Created By
            </th>
            <th
              scope="col"
              class="
                px-6
                py-3
                text-left text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              "
            >
            Bank
            </th>
  
            <th
              scope="col"
              class="
                px-6
                py-3
                text-left text-xs
                font-medium
                text-gray-500
                uppercase
                tracking-wider
              "
            >
         Total Sales
            </th>
            <th
              scope="col"
              class="
                px-6
                py-3
                text-xs
                font-medium
                text-right text-gray-500
                uppercase
                tracking-wider
              "
            >
            Cash Deposited
            </th>
            <th
              scope="col"
              class="
                px-6
                py-3
                text-xs
                font-medium
                text-right text-gray-500
                uppercase
                tracking-wider
              "
            >
            Reconciled
            </th>

            <th
              scope="col"
              class="
                px-6
                py-3
                text-xs
                font-medium
                text-right text-gray-500
                uppercase
                tracking-wider
              "
            >
          Difference
            </th>

          </tr>
        </thead>
  
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="(transaction, i) in data" :key="i">
            <td class="px-6 py-4 whitespace-nowrap">
              <div class="flex items-center">
                <div class="ml-4">
                  <div class="text-sm font-medium text-gray-900">
                    {{ transaction.date }}
                  </div>
                </div>
              </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-center">
              <div class="text-sm text-gray-900">
                {{ transaction.branch_name }}
              </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-center">
              <div class="text-sm text-gray-900">
                {{ transaction.created_by }}
              </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-center">
              <div class="text-sm text-gray-900">
                {{ transaction.bank}}
              </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-center">
              <div class="text-sm text-gray-900">
                GMD <b>{{ formatPrice(transaction.total_sales) }}</b>
              </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-center">
              <div class="text-sm text-gray-900">
                GMD <b>{{ formatPrice(transaction.total_deposited) }}</b>
              </div>
            </td>

            <td class="px-6 py-4 whitespace-nowrap text-center">
              <div class="text-sm text-gray-900">
                {{ transaction.isReconciled ? 'RECONCILED': 'PENDING' }}
              </div>
            </td>
  
           
            <td class="px-6 py-4 whitespace-nowrap  text-right">
              <div class="text-sm text-gray-900">
                <b>{{ formatPrice(transaction.difference) }}</b>
              </div>
            </td>
          </tr>
  
    
          <tr>
            <td class="px-6 py-4 whitespace-nowrap text-center font-bold">Total</td>
            <td class="px-6 py-4 whitespace-nowrap"></td>
          
            <td class="px-6 py-4 whitespace-nowrap text-center">
              <div class="text-sm text-gray-900">
              </div>
            </td>
          
            <td class="px-6 py-4 whitespace-nowrap text-center">
              <div class="text-sm text-gray-900">
              </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-center">
              <div class="text-sm text-gray-900">
                GMD <b>{{ formatPrice(totalSales) }}</b>
        
              </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-center">
              <div class="text-sm text-gray-900">
                GMD <b>{{ formatPrice(totalDeposited) }}</b>
              </div>
            </td>
          
            <td class="px-6 py-4 whitespace-nowrap text-center">
              <div class="text-sm text-gray-900">
              </div>
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-right">
                <div class="text-sm text-gray-900 ">
                  GMD <b>{{ formatPrice(totalDifference) }}</b>
                </div>
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    props: ["data"],
  
    data: () => ({
      downloadLoading: false,
  
      filename: "",
      autoWidth: true,
      bookType: "xlsx",
    }),
  
    computed: {

      totalDeposited: function () {
      return this.data.reduce((total, transaction) => total + transaction.total_deposited, 0);
    },
    totalCashInHand: function () {
        return this.data.reduce((total, transaction) => total + transaction.cash_in_hand, 0);
      },
      totalSales: function () {
        return this.data.reduce((total, transaction) => total + transaction.total_sales, 0);
      },

      totalDifference: function () {
        return this.totalDeposited - this.totalSales;
      },

      
      totalTransactions: function () {
        let total = [];
  
        Object.entries(this.data).forEach(([key, val]) => {
          console.log(key);
          total.push(val.transactions);
        });
  
        return total.reduce(function (total, num) {
          return total + num;
        }, 0);
      },
  
      totalAmount: function () {
        let total = [];
  
        Object.entries(this.data).forEach(([key, val]) => {
          console.log(key);
          total.push(val.amount);
        });
  
        return total.reduce(function (total, num) {
          return total + num;
        }, 0);
      },
  
      
  
      total: function () {
        let total = [];
  
        Object.entries(this.data).forEach(([key, val]) => {
          console.log(key);
          total.push(val.amount + val.commission);
        });
  
        return total.reduce(function (total, num) {
          return total + num;
        }, 0);
      },
    },
  
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
  
      
  
      getFileName() {
        return (
          this.$route.params.reportOn +
          "-" +
          this.$route.params.from +
          "-" +
          this.$route.params.to
        );
      },
  
      calculateTotal(data, key) {
    return data.reduce((total, transaction) => total + transaction[key], 0);
},


getTableHeaders(table) {
  const headerRow = table.querySelector("thead tr"); 
  const headers = [];
  headerRow.querySelectorAll("th").forEach((header) => {
    headers.push(header.textContent.trim());
  });
  return headers;
},
   
handleDownload() {
  this.downloadLoading = true;
  import("@/vendor/Export2Excel").then((excel) => {
    const tHeader = [
      "Date",
      "Branch",
      "Created By",
      "Bank",
      "Total Sales",
      "Cash Deposited",
      "Reconciled",
      "Difference",
    ];

    const filterVal = [
      "date",
      "branch_name",
      "created_by",
      "bank",
      "total_sales",
      "total_deposited",
      "isReconciled",
      "difference",
    ];

    const data = this.data.map((transaction) => {
      const rowData = filterVal.map((key) => {
        if (key === "date") {
          return transaction[key];
        } else if (key === "total_sales" || key === "total_deposited" || key === "difference") {
          return `GMD ${this.formatPrice(transaction[key])}`;
        } else if (key === "isReconciled") {
          return transaction[key] ? "RECONCILED" : "PENDING";
        } else {
          return transaction[key];
        }
      });
      return rowData;
    });


    const totalDeposited = this.formatPrice(this.calculateTotal(this.data, "total_deposited"));
    const totalSales = this.formatPrice(this.calculateTotal(this.data, "total_sales"));
    const totalDifference = this.formatPrice(this.totalDeposited - this.totalSales);

    const totals = [
      "Total(GMD)",
      "",
      "",
      "",
      totalSales,
      totalDeposited,
      "",
      totalDifference,
    ];

    data.push(totals);

    excel.export_json_to_excel({
      header: tHeader,
      data,
      filename: this.getFileName(),
      autoWidth: this.autoWidth,
      bookType: this.bookType,
    });
    this.downloadLoading = false;
  });
},











    
    },
  };
  </script>
  